// extracted by mini-css-extract-plugin
export var allLink = "DownloadResources__allLink__K15X9";
export var allResourcesListItem = "DownloadResources__allResourcesListItem__Es2vE";
export var column = "DownloadResources__column__yz9_F";
export var header = "DownloadResources__header__SeTzj";
export var li = "DownloadResources__li__qvwZj";
export var listItemWrapper = "DownloadResources__listItemWrapper__Krexq";
export var resourceName = "DownloadResources__resourceName__zYfCU";
export var resourceQty = "DownloadResources__resourceQty__lGAsl";
export var row = "DownloadResources__row__oI14L";
export var section = "DownloadResources__section__nApoM";
export var ul = "DownloadResources__ul__T8Cl8";