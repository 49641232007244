// extracted by mini-css-extract-plugin
export var badgeInactive = "ResourceDetail__badgeInactive__AZXS9";
export var badgeWrapper = "ResourceDetail__badgeWrapper__KRx_4";
export var breadcrumbContainer = "ResourceDetail__breadcrumbContainer__QHrsX";
export var breadcrumbText = "ResourceDetail__breadcrumbText__R84SL";
export var breadcrumbWrapper = "ResourceDetail__breadcrumbWrapper__vrReF";
export var column = "ResourceDetail__column__wlt0_";
export var container = "ResourceDetail__container__ZO0iv";
export var contentContainer = "ResourceDetail__contentContainer__tMdXz";
export var detailText = "ResourceDetail__detailText__KcEMQ";
export var downloadButton = "ResourceDetail__downloadButton__haW2q";
export var fileContainer = "ResourceDetail__fileContainer__poYGR";
export var grid = "ResourceDetail__grid__unNYt";
export var lineSeperator = "ResourceDetail__lineSeperator__aGwGn";
export var mobileHiddenImageContainer = "ResourceDetail__mobileHiddenImageContainer__Xb8y2";
export var mobileImage = "ResourceDetail__mobileImage__nxSjT";
export var mobileImageContainer = "ResourceDetail__mobileImageContainer__MzEOG";
export var resourceTitle = "ResourceDetail__resourceTitle__BWQ0m";
export var row = "ResourceDetail__row__Fq0E6";
export var sideColumn = "ResourceDetail__sideColumn__fhBTW";
export var summaryText = "ResourceDetail__summaryText__WxNze";